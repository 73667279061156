import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { socketConnection } from "../../services/socket";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";

import IconButton from "@material-ui/core/IconButton";
import BlockIcon from "@material-ui/icons/Block";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import ConfirmationModal from "../../components/ConfirmationModal/";
import ContactModal from "../../components/ContactModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import api from "../../services/api";

import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { Can } from "../../components/Can";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import NewTicketModal from "../../components/NewTicketModal";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import {
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { Card } from "../../components/Card/index";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      // const contactIndex = state.findIndex((c) => c.id === contact.id);
      // if (contactIndex !== -1) {
      //   state[contactIndex] = contact;
      // } else {
      // }
      newContacts.push(contact);
    });

    return [...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  btn: {
    color: "#fff",
    backgroundColor: "#9747FF",
    "&:hover": {
      backgroundColor: "#523197",
    },
  },
  dropdownBtn: {
    color: "#757575",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#523197",
    },
  },
}));

const formInputStyle = makeStyles((theme) => ({
  root: {
    "& label": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      "& input": {
        padding: "0.625rem",
        minWidth: "20.875rem",
      },
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e0e0e0",
      },
    },
  },
}));

// const fakeContacts = [
//   {
//     id: 1,
//     profilePicUrl: "http://profilepicture.com",
//     name: "João",
//     number: "11999999999",
//     email: "asdasdasdasd@asdasd.com",
//     active: true,
//   },
//   {
//     id: 2,
//     profilePicUrl: "http://profilepicture.com",
//     name: "Maria",
//     number: "11999999999",
//     email: "asdasdasdasd@asdasd.com",
//     active: false,
//   },
//   {
//     id: 3,
//     profilePicUrl: "http://profilepicture.com",
//     name: "José",
//     number: "11999999999",
//     email: "asdasdasdasd@asdasd.com",
//     active: true,
//   },
//   {
//     id: 4,
//     profilePicUrl: "http://profilepicture.com",
//     name: "Ana",
//     number: "11999999999",
//     email: "asdasdasdasd@asdasd.com",
//     active: true,
//   },
// ];

const Contacts = () => {
  const classes = useStyles();
  const searchInputClasses = formInputStyle();
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [ImportContacts, setImportContacts] = useState(null);
  const [blockingContact, setBlockingContact] = useState(null);
  const [unBlockingContact, setUnBlockingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmChatsOpen, setConfirmChatsOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({});
  const [limit, setLimit] = useState(20);
  const fileUploadRef = useRef(null);

  const handleImportExcel = async () => {
    try {
      const formData = new FormData();
      formData.append("file", fileUploadRef.current.files[0]);
      await api.request({
        url: `/contacts/upload`,
        method: "POST",
        data: formData,
      });
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const handleExportExcel = async () => {
    try {
      const companyId = localStorage.getItem("companyId");
      const { data } = await api.post(`/contacts/export`, { companyId });

      const url = `${process.env.REACT_APP_BACKEND_URL}/public/${data}`;

      window.open(url, "_blank");
    } catch (err) {
      toastError(err);
    }
  };

  // const fetchContacts = async (pageNumber) => {
  //   setLoading(true);

  //   try {
  //     const searchPage = +pageNumber;
  //     const searchLimit = +limit;

  //     const { data } = await api.get("/contacts/", {
  //       params: { searchParam, searchPage, searchLimit },
  //     });

  //     const totalPages = Math.ceil(data.count / limit);

  //     setTotalPages(totalPages);
  //     dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
  //     setHasMore(data.hasMore);
  //   } catch (err) {
  //     toastError(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleChange = (e, value) => {
    e.preventDefault();

    setPageNumber(value);
    // fetchContacts(value);
  };

  const handleLimitChange = (e) => {
    e.preventDefault();

    setLimit(e.target.value);
  };

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/", {
            params: { searchParam, pageNumber, limit },
          });

          const totalPages = Math.ceil(data.count / limit);
          setTotalPages(totalPages);
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          //   dispatch({ type: "LOAD_CONTACTS", payload: fakeContacts });
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, limit]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setSelectedContact(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setSelectedContact(null);
    setContactModalOpen(false);
  };

  const handleSaveTicket = async (contactId) => {
    if (!contactId) return;
    setLoading(true);
    try {
      const { data: ticket } = await api.post("/tickets", {
        contactId: contactId,
        userId: user?.id,
        status: "open",
      });
      history.push(`/tickets/${ticket.uuid}`);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  };

  const hadleEditContact = (contactId, contact) => {
    setSelectedContact(contact);
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleBlockContact = async (contactId) => {
    try {
      await api.put(`/contacts/block/${contactId}`, { active: false });
      toast.success("Contato bloqueado");
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleUnBlockContact = async (contactId) => {
    try {
      await api.put(`/contacts/block/${contactId}`, { active: true });
      toast.success("Contato desbloqueado");
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleimportContact = async () => {
    try {
      await api.post("/contacts/import");
      history.go(0);

      // setImportContacts(false);
    } catch (err) {
      toastError(err);
      //setImportContacts(false);
    }
  };

  const handleimportChats = async () => {
    try {
      await api.post("/contacts/import/chats");
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  function getDateLastMessage(contact) {
    if (!contact) return null;
    if (!contact.tickets) return null;

    if (contact.tickets.length > 0) {
      const date = new Date(
        contact.tickets[contact.tickets.length - 1].updatedAt
      );

      const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      const hours = date.getHours();
      const minutes = date.getMinutes();

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    return null;
  }

  return (
    <MainContainer className={classes.mainContainer}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        initialContact={contactTicket}
        onClose={(ticket) => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <ContactModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        selectedContact={selectedContact}
        contactId={selectedContactId}
      ></ContactModal>
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${
                deletingContact.name
              }?`
            : blockingContact
            ? `Bloquear Contato ${blockingContact.name}?`
            : unBlockingContact
            ? `Desbloquear Contato ${unBlockingContact.name}?`
            : ImportContacts
            ? `${i18n.t("contacts.confirmationModal.importTitlte")}`
            : `${i18n.t("contactListItems.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          deletingContact
            ? handleDeleteContact(deletingContact.id)
            : blockingContact
            ? handleBlockContact(blockingContact.id)
            : unBlockingContact
            ? handleUnBlockContact(unBlockingContact.id)
            : ImportContacts
            ? handleimportContact()
            : handleImportExcel()
        }
      >
        {deletingContact
          ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
          : blockingContact
          ? `Tem certeza que deseja bloquear este contato?`
          : unBlockingContact
          ? `Tem certeza que deseja desbloquear este contato?`
          : ImportContacts
          ? `${i18n.t("contacts.confirmationModal.importMessage")}`
          : `${i18n.t("contactListItems.confirmationModal.importMessage")}`}
      </ConfirmationModal>
      <ConfirmationModal
        title={"Importar Conversas"}
        open={confirmChatsOpen}
        onClose={setConfirmChatsOpen}
        onConfirm={(e) => handleimportChats()}
      >
        Deseja importar todas as conversas do telefone?
      </ConfirmationModal>
      <MainHeader>
        {/* <Title>
          {i18n.t("contacts.title")} ({contacts.length})
        </Title> */}
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            variant="outlined"
            value={searchParam}
            onChange={handleSearch}
            classes={searchInputClasses}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i style={{ color: "gray" }} className={"fi-rr-search"}></i>
                  {/* <SearchIcon style={{ color: "gray" }} /> */}
                </InputAdornment>
              ),
            }}
          />
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  {...bindTrigger(popupState)}
                >
                  Importar / Exportar
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    className={classes.dropdownBtn}
                    onClick={() => {
                      setConfirmOpen(true);
                      setImportContacts(true);
                      popupState.close();
                    }}
                  >
                    Importar Agenda
                  </MenuItem>
                  <MenuItem
                    className={classes.dropdownBtn}
                    onClick={() => {
                      fileUploadRef.current.value = null;
                      fileUploadRef.current.click();
                    }}
                  >
                    Importar Excel
                  </MenuItem>
                  <MenuItem
                    className={classes.dropdownBtn}
                    onClick={() => {
                      handleExportExcel();
                      popupState.close();
                    }}
                  >
                    Exportar Excel
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handleOpenContactModal}
          >
            {i18n.t("contacts.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Card fullWidth title="CONTATOS">
        <Paper
          className={classes.mainPaper}
          style={{
            marginTop: "0.625rem",
            boxShadow: "none",
            padding: 0,
            overflowY: "none",
            border: "none",
          }}
          variant="outlined"
          onScroll={handleScroll}
        >
          <>
            <input
              style={{ display: "none" }}
              id="upload"
              name="file"
              type="file"
              accept=".xls,.xlsx"
              onChange={() => {
                setConfirmOpen(true);
              }}
              ref={fileUploadRef}
            />
          </>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell style={{ textAlign: "start" }}>
                  {i18n.t("contacts.table.name")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("contacts.table.whatsapp")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("contacts.table.email")}
                </TableCell>
                <TableCell align="center">{"Ultima mensagem"}</TableCell>
                <TableCell align="center">{"Status"}</TableCell>
                <TableCell style={{ textAlign: "end" }}>
                  {i18n.t("contacts.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {loading ? (
                  <>
                    <TableRowSkeleton avatar columns={5} />
                    <TableRowSkeleton avatar columns={5} />
                    <TableRowSkeleton avatar columns={5} />
                    <TableRowSkeleton avatar columns={5} />
                    <TableRowSkeleton avatar columns={5} />
                    <TableRowSkeleton avatar columns={5} />
                  </>
                ) : (
                  contacts.map((contact) => (
                    <TableRow key={contact.id}>
                      <TableCell style={{ paddingRight: 0 }}>
                        {<Avatar src={contact.profilePicUrl} />}
                      </TableCell>
                      <TableCell>{contact.name}</TableCell>
                      <TableCell align="center">{contact.number}</TableCell>
                      <TableCell align="center">{contact.email}</TableCell>
                      <TableCell align="center">
                        {getDateLastMessage(contact)}
                      </TableCell>
                      <TableCell align="center">
                        {contact.active ? (
                          <CheckCircleIcon
                            style={{ color: "green" }}
                            fontSize="small"
                          />
                        ) : (
                          <CancelIcon
                            style={{ color: "red" }}
                            fontSize="small"
                          />
                        )}
                      </TableCell>
                      <TableCell style={{ textAlign: "end" }}>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setContactTicket(contact);
                            setNewTicketModalOpen(true);
                            // handleSaveTicket(contact.id);
                          }}
                        >
                          <i
                            className={"fi-brands-whatsapp"}
                            style={{ color: "#26A326" }}
                          ></i>

                          {/* <WhatsAppIcon color="secondary" /> */}
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={() => hadleEditContact(contact.id, contact)}
                        >
                          <i className={"fi-rr-pencil"}></i>
                          {/* <EditIcon color="secondary" /> */}
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={
                            contact.active
                              ? () => {
                                  setConfirmOpen(true);
                                  setBlockingContact(contact);
                                }
                              : () => {
                                  setConfirmOpen(true);
                                  setUnBlockingContact(contact);
                                }
                          }
                        >
                          {contact.active ? (
                            <BlockIcon color="secondary" />
                          ) : (
                            <CheckCircleIcon color="secondary" />
                          )}
                        </IconButton>
                        <Can
                          role={user.profile}
                          perform="contacts-page:deleteContact"
                          yes={() => (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                setConfirmOpen(true);
                                setDeletingContact(contact);
                              }}
                            >
                              <i
                                className={"fi-rr-trash"}
                                style={{ color: "#EE3736" }}
                              ></i>
                            </IconButton>
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </>
            </TableBody>
          </Table>
          <div
            style={{
              display: "flex",
              marginTop: "0.625rem",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Pagination
              count={totalPages}
              page={+pageNumber}
              onChange={handleChange}
              disabled={totalPages <= 1}
              color="secondary"
              variant="outlined"
              shape="rounded"
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">Quantidade</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={limit}
                label="quantidade"
                onChange={handleLimitChange}
                style={{
                  width: "150px",
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Paper>
      </Card>
    </MainContainer>
  );
};

export default Contacts;
