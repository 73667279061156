import { FormControl, InputLabel, Menu, MenuItem, Select } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Pagination from "@material-ui/lab/Pagination";
import { capitalize } from "lodash";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Card } from "../../components/Card";
import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import ScheduleModal from "../../components/ScheduleModal";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import usePlans from "../../hooks/usePlans";
import api from "../../services/api";
import { socketConnection } from "../../services/socket";
import { i18n } from "../../translate/i18n";

// A custom hook that builds on useLocation to parse
// the query string for you.
const getUrlParam = (param) => {
  return new URLSearchParams(window.location.search).get(param);
};

const reducer = (state, action) => {
  if (action.type === "LOAD_SCHEDULES") {
    const schedules = action.payload;
    const newSchedules = [];

    schedules.forEach((schedule) => {
      // const scheduleIndex = state.findIndex((s) => s.id === schedule.id);
      // if (scheduleIndex !== -1) {
      //   state[scheduleIndex] = schedule;
      // } else {
      //   newSchedules.push(schedule);
      // }
      newSchedules.push(schedule);
    });

    return [...newSchedules];
  }

  if (action.type === "UPDATE_SCHEDULES") {
    const schedule = action.payload;
    const scheduleIndex = state.findIndex((s) => s.id === schedule.id);

    if (scheduleIndex !== -1) {
      state[scheduleIndex] = schedule;
      return [...state];
    } else {
      return [schedule, ...state];
    }
  }

  if (action.type === "DELETE_SCHEDULE") {
    const scheduleId = action.payload;

    const scheduleIndex = state.findIndex((s) => s.id === scheduleId);
    if (scheduleIndex !== -1) {
      state.splice(scheduleIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const clearModalReducer = (state, action) => {
  switch (action.type) {
    case "ERRORS":
      return {
        modal: true,
        status: "ERRO",
      };
    case "SENTS":
      return {
        modal: true,
        status: "ENVIADA",
      };
    case "CLOSE":
      return {
        modal: false,
        status: null,
      };
    default:
      return state;
  }
};

const clearForceModalReducer = (state, action) => {
  switch (action.type) {
    case "PENDENTE":
      return {
        modal: true,
        status: "PENDENTE",
      };
    case "ERRO":
      return {
        modal: true,
        status: "ERRO",
      };
    case "CLOSE":
      return {
        modal: false,
        status: null,
      };
    default:
      return state;
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  btn: {
    color: "#fff",
    backgroundColor: "#9747FF",
    "&:hover": {
      backgroundColor: "#523197",
    },
  },
  dropdownBtn: {
    color: "#757575",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#523197",
    },
  },
}));

const formInputStyle = makeStyles((theme) => ({
  root: {
    "& label": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      "& input": {
        padding: "0.625rem",
        minWidth: "20.875rem",
      },
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e0e0e0",
      },
    },
  },
}));

const Schedules = () => {
  const searchInputClasses = formInputStyle();
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [deletingSchedule, setDeletingSchedule] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [schedules, dispatch] = useReducer(reducer, []);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [contactId, setContactId] = useState(+getUrlParam("contactId"));
  const [forceModelOpen, dispatchForceModelOpen] = useReducer(
    clearForceModalReducer,
    { modal: false, status: null }
  );
  const [clearExpiredModelOpen, dispatchModelOpen] = useReducer(
    clearModalReducer,
    { modal: false, status: null }
  );

  const { getPlanCompany } = usePlans();

  useEffect(() => {
    async function fetchData() {
      const companyId = localStorage.getItem("companyId");
      const planConfigs = await getPlanCompany(undefined, companyId);
      if (!planConfigs.plan.useSchedules) {
        toast.error(
          "Esta empresa não possui permissão para acessar essa página! Estamos lhe redirecionando."
        );
        setTimeout(() => {
          history.push(`/`);
        }, 1000);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleForceSend = async () => {
    try {
      const { data } = await api.post("/schedules/force", {
        status: forceModelOpen.status,
      });

      !data.message && toast.error(data.error);
      toast.success(data.message);
    } catch (error) {
      toast.error("Erro ao na rota de forçar", error);
    } finally {
      dispatchForceModelOpen({ type: "CLOSE" });
    }
  };

  const handleClearExpiredSchedules = async () => {
    try {
      const { data } = await api.delete("/schedules/expired/expired", {
        params: { status: clearExpiredModelOpen.status },
      });

      if (data.message) {
        toast.success(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      toast.error("Erro ao enviar mensagens.", error);
    } finally {
      setDeletingSchedule(null);
      setSearchParam("");
      setPageNumber(1);

      dispatch({ type: "RESET" });
      setPageNumber(1);
      await fetchSchedules();
    }
  };

  const fetchSchedules = useCallback(async () => {
    try {
      const { data } = await api.get("/schedules/", {
        params: { searchParam, pageNumber, limit },
      });
      const totalPages = Math.ceil(data.count / limit);
      setTotalPages(totalPages);
      dispatch({ type: "LOAD_SCHEDULES", payload: data.schedules });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber, limit]);

  const handleOpenScheduleModalFromContactId = useCallback(() => {
    if (contactId) {
      handleOpenScheduleModal();
    }
  }, [contactId]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      console.log("FETCH_SCHEDULES");
      fetchSchedules();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [
    searchParam,
    pageNumber,
    contactId,
    fetchSchedules,
    handleOpenScheduleModalFromContactId,
  ]);

  useEffect(() => {
    handleOpenScheduleModalFromContactId();
    const socket = socketConnection({ companyId: user.companyId });

    socket.on("user", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_SCHEDULES", payload: data.schedules });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.scheduleId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [handleOpenScheduleModalFromContactId, user]);

  const cleanContact = () => {
    setContactId("");
  };

  const handleOpenScheduleModal = () => {
    setSelectedSchedule(null);
    setScheduleModalOpen(true);
  };

  const handleOpenForceModal = () => {
    dispatchForceModelOpen({ type: "CLOSE" });
  };

  const handleCloseScheduleModal = () => {
    setSelectedSchedule(null);
    setScheduleModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditSchedule = (schedule) => {
    setSelectedSchedule(schedule);
    setScheduleModalOpen(true);
  };

  const handleDeleteSchedule = async (scheduleId) => {
    try {
      await api.delete(`/schedules/${scheduleId}`);
      toast.success(i18n.t("schedules.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingSchedule(null);
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchSchedules();
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const truncate = (str, len) => {
    if (str.length > len) {
      return str.substring(0, len) + "...";
    }
    return str;
  };

  const handleChange = (e, value) => {
    e.preventDefault();

    setPageNumber(value);
  };

  const handleLimitChange = (e) => {
    e.preventDefault();

    setLimit(e.target.value);
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingSchedule &&
          `${i18n.t("schedules.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteSchedule(deletingSchedule.id)}
      >
        {i18n.t("schedules.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ConfirmationModal
        title={`Tem certeza que deseja forçar o envio para os agendamentos ${
          forceModelOpen.status === "PENDENTE" ? "pendentes" : "com erro"
        }?`}
        open={forceModelOpen.modal}
        onClose={handleOpenForceModal}
        onConfirm={() => handleForceSend()}
      >
        {forceModelOpen.status === "PENDENTE"
          ? "Essa ação não pode ser desfeita. Após o OK todos os agendamentos PENDENTES serão enviados mesmo que não tenham chegado na sua data agendada."
          : "Essa ação não pode ser desfeita. Após o OK todos os agendamentos com ERRO serão reenviados."}
      </ConfirmationModal>
      <ConfirmationModal
        title="Tem certeza que deseja limpar os agendamentos expirados?"
        open={clearExpiredModelOpen.modal}
        onClose={() => dispatchModelOpen({ type: "CLOSE" })}
        onConfirm={() => handleClearExpiredSchedules()}
      >
        Essa ação não pode ser desfeita. E todos os agendamentos serão expirados
        e serão apagados imediatamente.
      </ConfirmationModal>
      <ScheduleModal
        open={scheduleModalOpen}
        onClose={handleCloseScheduleModal}
        reload={fetchSchedules}
        aria-labelledby="form-dialog-title"
        scheduleId={selectedSchedule && selectedSchedule.id}
        contactId={contactId}
        cleanContact={cleanContact}
      />
      <MainHeader>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            variant="outlined"
            classes={searchInputClasses}
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i style={{ color: "gray" }} className={"fi-rr-search"}></i>
                </InputAdornment>
              ),
            }}
          />
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  {...bindTrigger(popupState)}
                >
                  RE-ENVIAR / FORÇAR ENVIO
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    className={classes.dropdownBtn}
                    onClick={() => {
                      dispatchForceModelOpen({ type: "ERRO" });
                    }}
                  >
                    RE-ENVIAR TODOS COM ERRO
                  </MenuItem>
                  <MenuItem
                    className={classes.dropdownBtn}
                    onClick={() => {
                      dispatchForceModelOpen({ type: "PENDENTE" });
                    }}
                  >
                    FORÇAR TODOS PENDENTES
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  {...bindTrigger(popupState)}
                >
                  DELETAR
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    className={classes.dropdownBtn}
                    onClick={() => {
                      dispatchModelOpen({ type: "ERRORS" });
                    }}
                  >
                    DELETAR TODOS COM ERRO
                  </MenuItem>
                  <MenuItem
                    className={classes.dropdownBtn}
                    onClick={() => {
                      dispatchModelOpen({ type: "SENTS" });
                    }}
                  >
                    DELETAR TODOS ENVIADOS
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handleOpenScheduleModal}
          >
            {i18n.t("schedules.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Card fullWidth title={i18n.t("schedules.title").toUpperCase()}>
        <Paper
          style={{
            marginTop: "0.625rem",
            boxShadow: "none",
            padding: 0,
            overflowY: "none",
            border: "none",
          }}
          className={classes.mainPaper}
          variant="outlined"
          onScroll={handleScroll}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: "start" }}>
                  {i18n.t("schedules.table.contact")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("schedules.table.body")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("schedules.table.sendAt")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("schedules.table.status")}
                </TableCell>
                <TableCell style={{ textAlign: "end" }}>
                  {i18n.t("schedules.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {schedules.map((schedule) => (
                  <TableRow key={schedule.id}>
                    <TableCell style={{ textAlign: "start" }}>
                      {schedule.contact.name}
                    </TableCell>
                    <TableCell align="center" title={schedule.body}>
                      {truncate(schedule.body, 25)}
                    </TableCell>
                    <TableCell align="center">
                      {moment(schedule.sendAt).format("DD/MM/YYYY HH:mm:ss")}
                    </TableCell>
                    <TableCell align="center">
                      {capitalize(schedule.status)}
                    </TableCell>
                    <TableCell style={{ textAlign: "end" }}>
                      <IconButton
                        size="small"
                        onClick={() => handleEditSchedule(schedule)}
                      >
                        <i
                          style={{ color: "gray" }}
                          className={"fi-rr-pencil"}
                        ></i>
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setConfirmModalOpen(true);
                          setDeletingSchedule(schedule);
                        }}
                      >
                        <i
                          style={{ color: "red" }}
                          className={"fi-rr-trash"}
                        ></i>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={5} />}
              </>
            </TableBody>
          </Table>
          <div
            style={{
              display: "flex",
              marginTop: "0.625rem",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Pagination
              count={totalPages}
              page={+pageNumber}
              onChange={handleChange}
              disabled={totalPages <= 1}
              color="secondary"
              variant="outlined"
              shape="rounded"
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">Quantidade</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={limit}
                label="quantidade"
                onChange={handleLimitChange}
                style={{
                  width: "150px",
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Paper>
      </Card>
    </MainContainer>
  );
};

export default Schedules;
